<template>
  <table
    class="table table-bordered table-striped"
    role="grid"
  >
    <colgroup>
      <col style="width: 41.77%;">
      <col style="width: 20.18%;">
      <col style="width: 21.73%;">
      <col style="width: 16.3%;">
    </colgroup>
    <thead>
      <tr
        role="row"
        class="tablesorter-headerRow"
      >
        <th
          style="text-align: center; user-select: none; min-width: 8px; max-width: none;"
          data-column="0"
          tabindex="0"
          scope="col"
          role="columnheader"
        >
          <div class="tablesorter-header-inner">
            Действие/Страница
          </div>
        </th><th
          data-column="1"
          tabindex="0"
          scope="col"
          role="columnheader"

          style="user-select: none; min-width: 8px; max-width: none;"
        >
          <div class="tablesorter-header-inner">
            Администратор
          </div>
        </th><th
          data-column="2"
          tabindex="0"
          scope="col"
          role="columnheader"
          style="user-select: none; min-width: 8px; max-width: none;"
        >
          <div class="tablesorter-header-inner">
            Менеджер
          </div>
        </th><th
          data-column="3"
          tabindex="0"
          scope="col"
          role="columnheader"
          style="user-select: none; min-width: 8px; max-width: none;"
        >
          <div class="tablesorter-header-inner">
            Просмотр
          </div>
        </th>
      </tr>
    </thead>
    <tbody
      aria-live="polite"
      aria-relevant="all"
    >
      <tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          <p>
            Создание контактных реквизитов для Перевозчиков
          </p>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          <p>
            Редактирование контактных реквизитов для Перевозчиков
          </p>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          <p>
            Удаление контактных реквизитов для Перевозчиков
          </p>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Приглашение новых пользователей Заказчика
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Удаление приглашенных пользователей Заказчика
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Добавление контактных реквизитов для конкретного Перевозчика
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td>
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Просмотр данных Перевозчика
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td>
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Просмотр прикрепленных документов Перевозчиком при регистрации
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td>
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Отклонения заявки Перевозчика на Верификацию
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Принятие заявки Перевозчика на Верификацию
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          <p>
            Создание Рейса на стороне ЭТП
          </p>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          <span>Редактирование Рейса на стороне ЭТП</span>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Удаление <span>Рейса на стороне ЭТП</span>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Принятие заявки на отказ от Рейса
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Отклонение заявки на отказ от Рейса
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          <span>07.05.2020.Редактирование прав и блокировка приглашенных пользователей заказчика.</span>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Создание/редактирование списков Перевозчиков
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Удаление списков перевозчиков
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        /><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Редактирование/верификация/аннулирование доверенностей перевозчиков
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Создание/редактирование/запуск/назначение победителя на аукционе.
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Отмена аукциона
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          Создание/редактирование/запуск/назначение победителя на тендере.
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr><tr role="row">
        <td
          colspan="1"
          class="confluenceTd"
        >
          <span>Отмена тендера</span>
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        >
          <span
            class="fa fa-check text-success bold"
          />
        </td><td
          colspan="1"
          class="confluenceTd"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'CustomerUsersRolesInfoTable',
};
</script>

<style scoped>

</style>
